import { useOs } from '@wppopen/react'
import { Fragment, useState, useEffect, useRef, useMemo } from 'react'

import { getChatByTenant } from 'app/utils'
import styles from 'components/chat/Chat.module.scss'
import { BotChatBubble as BotChatBubbleT1 } from 'components/template1/botChatBubble/BotChatBubble'
import { UserChatBubble as UserChatBubbleT1 } from 'components/template1/userChatBubble/UserChatBubble'
import { BotChatBubble as BotChatBubbleT2 } from 'components/template2/botChatBubble/BotChatBubble'
import { UserChatBubble as UserChatBubbleT2 } from 'components/template2/userChatBubble/UserChatBubble'
import { Templates } from 'constants/template'

export const Chat = () => {
  const { osContext } = useOs()

  const chatEndRef = useRef<HTMLDivElement>(null)

  const [chatProgressIndex, setChatProgressIndex] = useState(0)

  const handleContinueChat = () => {
    if (chatProgressIndex === chat.length - 1) return
    setChatProgressIndex(chatProgressIndex + 1)
  }

  const { chat, template } = getChatByTenant(osContext, handleContinueChat)

  const bgColor = useMemo(() => {
    switch (template) {
      case Templates.Template1:
        return 'var(--wpp-grey-color-200)'
      case Templates.Template2:
        return 'var(--wpp-grey-color-000)'
      default:
        return 'var(--wpp-grey-color-200)'
    }
  }, [template])

  const BotChatBubble = useMemo(() => {
    switch (template) {
      case Templates.Template1:
        return BotChatBubbleT1
      case Templates.Template2:
        return BotChatBubbleT2
      default:
        return BotChatBubbleT1
    }
  }, [template])

  const UserChatBubble = useMemo(() => {
    switch (template) {
      case Templates.Template1:
        return UserChatBubbleT1
      case Templates.Template2:
        return UserChatBubbleT2
      default:
        return UserChatBubbleT1
    }
  }, [template])

  const scrollToBottom = () => {
    requestAnimationFrame(() => {
      chatEndRef.current?.scrollIntoView({ behavior: 'smooth' })
    })
  }

  useEffect(() => {
    scrollToBottom()

    if (chatProgressIndex === chat.length - 1) return

    if (chat[chatProgressIndex]?.timeout) {
      setTimeout(() => {
        setChatProgressIndex(chatProgressIndex + 1)
      }, chat[chatProgressIndex].timeout)
    }
  }, [chatProgressIndex, chat])

  return (
    <div className={styles.container} style={{ backgroundColor: bgColor }}>
      {chat.map((chatItem, index) => (
        <Fragment key={index}>
          {index <= chatProgressIndex && (
            <>
              {chatItem.type === 'bot' && (
                <BotChatBubble
                  message={chatItem?.message}
                  complexMessage={chatItem?.complexMessage}
                  actions={chatItem?.actions}
                  imgUrl={chatItem?.imgUrl}
                  videoUrl={chatItem?.videoUrl}
                  showAvatar={chatItem?.showAvatar}
                />
              )}
              {chatItem.type === 'user' && <UserChatBubble message={chatItem?.message} />}
            </>
          )}
        </Fragment>
      ))}
      <div ref={chatEndRef} />
    </div>
  )
}
