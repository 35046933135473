// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eZjJm{display:inline-block;margin-bottom:8px;padding:16px 24px;background-color:var(--wpp-primary-color-100);border-radius:var(--wpp-border-radius-m)}.uv_Bp{width:56px}.f9A6q{margin-left:68px}.ZCURg{white-space:pre-wrap}.rjpcO{margin-top:8px}.XOqHf{margin-left:68px}.wZrVb{min-width:132px;height:42px;padding:0 16px;background-color:var(--wpp-grey-color-000);border:2px solid var(--wpp-primary-color-100);border-radius:24px;transition:background-color .25s ease-in-out}.wZrVb:hover{background-color:var(--wpp-primary-color-100)}.tjUHG,.fl_Lv{width:70vw;height:40.47vw;margin-bottom:8px;margin-left:68px}`, "",{"version":3,"sources":["webpack://./src/components/template1/botChatBubble/BotChatBubble.module.scss"],"names":[],"mappings":"AAAA,OACE,oBAAA,CACA,iBAAA,CACA,iBAAA,CACA,6CAAA,CACA,wCAAA,CAGF,OACE,UAAA,CAGF,OACE,gBAAA,CAGF,OACE,oBAAA,CAGF,OACE,cAAA,CAGF,OACE,gBAAA,CAGF,OACE,eAAA,CACA,WAAA,CACA,cAAA,CACA,0CAAA,CACA,6CAAA,CACA,kBAAA,CACA,4CAAA,CAEA,aACE,6CAAA,CAIJ,cACE,UAAA,CACA,cAAA,CACA,iBAAA,CACA,gBAAA","sourcesContent":[".bubble {\n  display: inline-block;\n  margin-bottom: 8px;\n  padding: 16px 24px;\n  background-color: var(--wpp-primary-color-100);\n  border-radius: var(--wpp-border-radius-m);\n}\n\n.avatarContainer {\n  width: 56px;\n}\n\n.noAvatarBubble {\n  margin-left: 68px;\n}\n\n.message {\n  white-space: pre-wrap;\n}\n\n.actions {\n  margin-top: 8px;\n}\n\n.noAvatarActions {\n  margin-left: 68px;\n}\n\n.actionBtn {\n  min-width: 132px;\n  height: 42px;\n  padding: 0 16px;\n  background-color: var(--wpp-grey-color-000);\n  border: 2px solid var(--wpp-primary-color-100);\n  border-radius: 24px;\n  transition: background-color 0.25s ease-in-out;\n\n  &:hover {\n    background-color: var(--wpp-primary-color-100);\n  }\n}\n\n.videoIframe, .videoPreviewImg {\n  width: 70vw;\n  height: 40.47vw;\n  margin-bottom: 8px;\n  margin-left: 68px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bubble": `eZjJm`,
	"avatarContainer": `uv_Bp`,
	"noAvatarBubble": `f9A6q`,
	"message": `ZCURg`,
	"actions": `rjpcO`,
	"noAvatarActions": `XOqHf`,
	"actionBtn": `wZrVb`,
	"videoIframe": `tjUHG`,
	"videoPreviewImg": `fl_Lv`
};
export default ___CSS_LOADER_EXPORT___;
