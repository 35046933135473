// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QejU9{margin-top:6px}.UaIpK{display:inline-block;margin-bottom:12px;padding:12px 16px;background-color:var(--wpp-grey-color-200);border-radius:var(--wpp-border-radius-m)}.M8Pqx{white-space:pre-wrap}`, "",{"version":3,"sources":["webpack://./src/components/template2/userChatBubble/UserChatBubble.module.scss"],"names":[],"mappings":"AAAA,OACE,cAAA,CAGF,OACE,oBAAA,CACA,kBAAA,CACA,iBAAA,CACA,0CAAA,CACA,wCAAA,CAGF,OACE,oBAAA","sourcesContent":[".bubbleContainer {\n  margin-top: 6px;\n}\n\n.bubble {\n  display: inline-block;\n  margin-bottom: 12px;\n  padding: 12px 16px;\n  background-color: var(--wpp-grey-color-200);\n  border-radius: var(--wpp-border-radius-m);\n}\n\n.message {\n  white-space: pre-wrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bubbleContainer": `QejU9`,
	"bubble": `UaIpK`,
	"message": `M8Pqx`
};
export default ___CSS_LOADER_EXPORT___;
