// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bK0lS{display:inline-block;margin-bottom:6px;padding:12px 16px;background-color:var(--wpp-grey-color-200);border-radius:var(--wpp-border-radius-m)}.j6ZGk{width:32px}.GC5c1{white-space:pre-wrap}.c3fbE{display:block;width:100%;max-width:640px;margin:16px 0}.EbFBY{margin:16px 0 32px}.bYM1k{color:var(--wpp-primary-color-500);text-decoration:underline}.a2vVn{margin-top:16px}.nQmYJ{margin-top:16px}.Wmt05::part(button){height:32px;padding:5px 16px}`, "",{"version":3,"sources":["webpack://./src/components/template2/botChatBubble/BotChatBubble.module.scss"],"names":[],"mappings":"AAAA,OACE,oBAAA,CACA,iBAAA,CACA,iBAAA,CACA,0CAAA,CACA,wCAAA,CAGF,OACE,UAAA,CAGF,OACE,oBAAA,CAGF,OACE,aAAA,CACA,UAAA,CACA,eAAA,CACA,aAAA,CAGF,OACE,kBAAA,CAGF,OACE,kCAAA,CACA,yBAAA,CAGF,OACE,eAAA,CAGF,OACE,eAAA,CAIA,qBACE,WAAA,CACA,gBAAA","sourcesContent":[".bubble {\n  display: inline-block;\n  margin-bottom: 6px;\n  padding: 12px 16px;\n  background-color: var(--wpp-grey-color-200);\n  border-radius: var(--wpp-border-radius-m);\n}\n\n.avatarContainer {\n  width: 32px;\n}\n\n.message {\n  white-space: pre-wrap;\n}\n\n.image {\n  display: block;\n  width: 100%;\n  max-width: 640px;\n  margin: 16px 0;\n}\n\n.linkContainer {\n  margin: 16px 0 32px;\n}\n\n.linkLabel {\n  color: var(--wpp-primary-color-500);\n  text-decoration: underline;\n}\n\n.selectContainer {\n  margin-top: 16px;\n}\n\n.actions {\n  margin-top: 16px;\n}\n\n.actionBtn {\n  &::part(button) {\n    height: 32px;\n    padding: 5px 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bubble": `bK0lS`,
	"avatarContainer": `j6ZGk`,
	"message": `GC5c1`,
	"image": `c3fbE`,
	"linkContainer": `EbFBY`,
	"linkLabel": `bYM1k`,
	"selectContainer": `a2vVn`,
	"actions": `nQmYJ`,
	"actionBtn": `Wmt05`
};
export default ___CSS_LOADER_EXPORT___;
