// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Zt1Dl{margin-top:8px}.m46Fe{display:inline-block;margin-bottom:16px;padding:16px 24px;background-color:var(--wpp-grey-color-000);border-radius:var(--wpp-border-radius-m)}.DRKR9{white-space:pre-wrap}`, "",{"version":3,"sources":["webpack://./src/components/template1/userChatBubble/UserChatBubble.module.scss"],"names":[],"mappings":"AAAA,OACE,cAAA,CAGF,OACE,oBAAA,CACA,kBAAA,CACA,iBAAA,CACA,0CAAA,CACA,wCAAA,CAGF,OACE,oBAAA","sourcesContent":[".bubbleContainer {\n  margin-top: 8px;\n}\n\n.bubble {\n  display: inline-block;\n  margin-bottom: 16px;\n  padding: 16px 24px;\n  background-color: var(--wpp-grey-color-000);\n  border-radius: var(--wpp-border-radius-m);\n}\n\n.message {\n  white-space: pre-wrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bubbleContainer": `Zt1Dl`,
	"bubble": `m46Fe`,
	"message": `DRKR9`
};
export default ___CSS_LOADER_EXPORT___;
