import { useOs } from '@wppopen/react'
import { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'

import styles from 'app/App.module.scss'
import { Chat } from 'components/chat/Chat'

export function App() {
  const { osContext } = useOs()

  useEffect(() => {
    document.body.classList.add(styles.root)

    return () => document.body.classList.remove(styles.root)
  }, [])

  return (
    <BrowserRouter basename={osContext.baseUrl}>
      <Chat />
    </BrowserRouter>
  )
}
